@import "utils/variables.module.scss";

.actions-container{
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;


  .left-slot{
    border-bottom-left-radius: 10px;
  }

  .right-slot{
      border-bottom-right-radius: 10px;
  }

  .act-container {
    display: flex;
    flex-direction: column;
    justify-content: center;

    padding-top: 15px; 
    padding-bottom: 10px; 
    padding-left: 20px; 
    padding-right: 20px; 

  }

}

.lock-popup{
  margin: 5px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 400px;
  min-width: 235px;
  background-color: $white-color;
  padding: 15px;
}

@media only screen and (min-width: 400px) {
  .act-container{
    padding-left: 32px !important;
    padding-right: 32px !important;
  }
}