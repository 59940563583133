@import "utils/variables.module.scss";

.error-container{
  background-color: $white-color;
  border-radius: 10px;
  padding: 20px;
}

.sticky-place-order{
  position: sticky;
  left: 0;
  bottom: 0;
}