@import "utils/variables.module.scss";

.top-container{
  background-color: $primary4-color;
}

.home-cover-bloc{
  display: table;
  position: relative;
  width: 100%;
  height: calc(100vh - 72px);
  height: calc(100svh - 72px) !important;
  min-height: min-content;
  background-color: black;
  /*background-image: url(/assets/images/home1.png);*/
/*  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;*/

  .features-box{
    position: relative;
    /*z-index: 1000;*/
  }

  .video-bg {
    width: 100%;
    height: calc(100vh - 72px);
    height: calc(100svh - 72px) !important;
    position: absolute;
    min-height: 100%;
    top: 0;
    /*z-index: -1;*/
    object-fit: cover;
  }

  .floating-logo{
    position: absolute;
    bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    .img {
      width: 40px !important;
      height: 40px;
      margin-bottom: 7px;
    }
  }


}

.txt-part{
  padding: 0px 50px;
  display: flex;
  flex-direction: column;
}
.img-part{
  display: flex;
  justify-content: center;
  padding: 0px 20px;
  .drawing{
    width: 650px; 
  }
}


.top-container{
  .img{
    width: 470px;
    height: 470px;
  }
}



.home-2-bloc{
  /*display: table;*/
  width: 100%;
  /*height: 100vh;*/
  /*height: 100svh !important;*/
  min-height: min-content;
  /*background-image: url(/assets/images/home2.png);*/
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-top: 120px;
  .img{
    background-image: url(/assets/images/home2.jpg); 
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover; 

    border-radius: 100%;
  }
}

.home-3-bloc{
  position: relative;
  /*display: table;*/
  width: 100%;
/*  height: 100vh;
  height: 100svh !important;*/
  min-height: min-content;
  /*background-image: url(/assets/images/home3.png);*/
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /*margin-top: -215px;*/
  .img{
    background-image: url(/assets/images/home3.jpg); 
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover; 
    height: 490px;
    width: 490px;
    border-radius: 100%;
  }
}

.home-4-bloc{
  /*display: table;*/
  width: 100%;
/*  height: 100vh;
  height: 100svh !important;*/
  min-height: min-content;
  /*background-image: url(/assets/images/home4.png);*/
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 70px;

  .img{
    background-image: url(/assets/images/home4bis.jpg); 
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover; 
    height: 490px;
    width: 490px;
    border-radius: 100%;
  }

  .drawing{
    margin: 50px 0;
    width: 500px;
  }
}

.ft-note{
  max-width: 800px;
}

.h-txt-mobile{
  display: none !important;
}

.h-txt-desktop{
  display: flex !important;
}

@media only screen and (max-width: 1230px) {
  .h-txt-mobile{
    display: flex !important;
  }

  .h-txt-desktop{
    display: none !important;
  }

  .img-part{
    margin-top: 30px !important;
    margin-bottom: 50px !important;
  }

  .txt-part{
    padding: 0 20px;
  }


  .home-2-bloc{
    margin-top: 70px;
  }

  .home-4-bloc{
    margin-bottom: 0;
  }


}

.h-big-title{
  margin-top: 40px;
  padding: 0 15px;
}

@media only screen and (max-width: 800px) {
  
  .top-container{
    .img{
      width: 70vw;
      height: 70vw;
    }
  }

  .home-2-bloc{
    margin-top: 40px;
  }

  .home-4-bloc{
    .drawing{
      margin: 0px 0;
    }
  }
  

  .h-big-title {
    margin-top: 0px;
    &.h3 {
      font-size: $h6+px;
    }
    &.h5 {
      font-size: $para1+px;
    }
  }

  .txt-part{
    .h5 {
      font-size: $para1+px;
    }
    .h3 {
      font-size: $h6+px;
    }
  }

 

  .home-4-bloc, .home-3-bloc{
    .marginTop{
      margin-top: 100px;
    }
  }
  .drawing{
    width: 100% !important; 
  }
}