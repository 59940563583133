@import "utils/variables.module.scss";

.sc-cover-bloc{
  display: flex;
  position: relative;
  width: 100%;
  height: calc(100vh);
  height: calc(100svh) !important;
  min-height: min-content;
  align-items: center;
  justify-content: center;
  background-image: url(/assets/images/womenstairs.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  filter: grayscale(100%);

  .floating-logo{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    img {
      width: 180px;
      margin-bottom: 50px;
    }
  }


}

@media only screen and (max-width: 750px) {
  
 .floating-logo{
    img {
      width: 100px !important;
    }
    .h3 {
      font-size: $h5+px !important;
    }
  }
}