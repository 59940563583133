
//colors

$primary1-color: #335E62;
$primary2-color: #437A85;
$primary3-color: #67979B;
$primary4-color: #B5D6CE;

$secondary1-color: #E9EAEA;
$secondary2-color: #CDB88D;

$accent-color: #FACB18;

$white-color: #FFFFFF;
$black-color: #000000;
$black-color-opac: rgba($black-color, .60); 
$gray-color: #818181;
$gray2-color: #D9D9D9;
$gray3-color: #E9EAEA;

$error-color: #FF0000;



//fonts

@font-face {
    font-family: futuraBold;
    src: url(/assets/fonts/Futura_Bold_font.ttf);
}

@font-face {
    font-family: futuraHeavy;
    src: url(/assets/fonts/Futura_Heavy_font.ttf);
}

@font-face {
    font-family: futuraMedium;
    src: url(/assets/fonts/futura_medium_bt.ttf);
}

@font-face {
    font-family: futuraBook;
    src: url(/assets/fonts/Futura_Book_font.ttf);
}

@font-face {
    font-family: futuraLight;
    src: url(/assets/fonts/Futura_Light_font.ttf);
}

@font-face {
    font-family: futuraLightBt;
    src: url(/assets/fonts/futura_light_bt.ttf);
}

$font-1-bold: 'futuraBold', sans-serif;
$font-1-heavy: 'futuraHeavy', sans-serif;
$font-1-medium: 'futuraMedium', sans-serif;
$font-1-book: 'futuraBook', sans-serif;
$font-1-light: 'futuraLight', sans-serif;
$font-1-light-bt: 'futuraLightBt', sans-serif;

/*$font-2: 'Poppins';*/




$h1: 76;
$h2: 64;
$h3: 48;
$h4: 40;
$h5: 32;
$h6: 24;

$para1: 20;
$para1-bis: 18;
$para2: 16;
$para3: 14;
$para4: 11;


:export{

  primary1-color: $primary1-color;
  primary2-color: $primary2-color;
  primary3-color: $primary3-color;
  primary4-color: $primary4-color;

  secondary1-color: $secondary1-color;
  secondary2-color: $secondary2-color;

  accent-color: $accent-color;

  white-color: $white-color;
  black-color: $black-color;
  black-color-opac: $black-color-opac;
  gray-color: $gray-color;
  error-color: $error-color;
  
  font-1-bold: $font-1-bold;
  font-1-heavy: $font-1-heavy;
  font-1-medium: $font-1-medium;
  font-1-book: $font-1-book;
  font-1-light: font-1-light;
  font-1-light-bt: font-1-light-bt;

  h1: $h1;
  h2: $h2;
  h3: $h3;
  h4: $h4;
  h5: $h5;
  h6: $h6;

  para1: $para1;
  para1-bis: $para1-bis;
  para2: $para2;
  para3: $para3;
  para4: $para4;

}
