@import "utils/variables.module.scss";

.ca-container{
  padding: 20px 40px;
  background: $primary4-color;
  border-radius: 10px;

  .img-btn{
    cursor: pointer;
    box-sizing: border-box;
    padding: 0px 10px;
    border: 1px dashed $primary1-color;
    border-radius: 10px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    &.active{
      border: 1px solid $primary1-color;

    }
  }
}

@media only screen and (max-width: 600px) {
  .ca-container {
    padding: 30px 20px;
    .h5{
      font-size: $para1+px;
    }

    .para1{
      font-size: $para2+px;
    }
  }
}
