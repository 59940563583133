
@import "utils/variables.module.scss";

.delimiter{
  width: 100%;
  height: 14px; 
  position: relative;
  .left{
    left: 0;
    position: absolute;
    width: 50%;
    height: 100%;
    background: $white-color;
  }
  .right{
    right: 0;
    position: absolute;
    width: 50%;
    height: 100%;
    background: $white-color;
  }
}

.delimiter.collection{
  .left{
    background: $primary4-color;
  }
  .right{
    background: $primary4-color;
  }
  &.bottom{
    .left{
      border-top: 2px dashed  rgba($white-color, .60);  
    }
    .right{
      border-top: 2px dashed  rgba($white-color, .60);  
    }
  }
}

.delimiter.delivery{
  .left{
    background: $primary1-color;
  }
  .right{
    background: $primary1-color;
  }
  &.bottom{
    .left{
      border-top: 2px dashed  rgba($white-color, .60);  
    }
    .right{
      border-top: 2px dashed  rgba($white-color, .60);  
    }
  }
}


.delimiter.top{
  .left{
    -webkit-mask-image: radial-gradient(circle 10px at 0px 16px, transparent 0, transparent 8px, black 8px);
  }
  .right{
    -webkit-mask-image: radial-gradient(circle 10px at 100% 16px, transparent 0, transparent 8px, black 8px);
  }
}

.delimiter.bottom{
  .left{
    -webkit-mask-image: radial-gradient(circle 10px at 0px 0px, transparent 0, transparent 8px, black 8px);
    border-top: 2px dashed  rgba($gray-color, .20);  
  }
  .right{
    -webkit-mask-image: radial-gradient(circle 10px at 100% 0px, transparent 0, transparent 8px, black 8px);
    border-top: 2px dashed  rgba($gray-color, .20);  
  }

  &.dark{
    .left{
      border-top: 2px dashed  $primary1-color !important;  
    }
    .right{
      border-top: 2px dashed $primary1-color !important;   
    }
  }
}

