@import "utils/variables.module.scss";


.floating-footer{
  position: absolute;
  bottom: 20px;
  display: flex;
  align-items: center;
  padding: 0px 20%;
  width: 100%;
  img {
    width: 40px;
    height: 40px;
    margin-bottom: 7px;
  }
}
