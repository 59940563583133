@import "utils/variables.module.scss";


.access-code-block{
  position: fixed;
  background: $primary1-color;
  width: 100%;
  bottom: 0px;
  left: 0;

  .field{
    height: 35px !important;
    /*margin-bottom: 20px;*/
    background: $white-color;
    &::placeholder{
      text-align: center;
    }
  }


}

