@import "utils/variables.module.scss";

.sm-container{
  
  background-color: $primary4-color;
  border-bottom: 1px solid rgba($black-color, .30);
  border-radius: 10px;

  .menu-item{
    .img1 {
      height: 88px;
    }
    .img2 {
      margin-top: 5px;
      margin-bottom: 5px;
      height: 78px;
    }
    padding-top: 20px;
    padding-bottom: 7px;
    margin: 0px 60px;
    .title{
      color: $primary1-color;
    }

    &.active{
      border-bottom: 6px solid $black-color;

      .title{
        color: $black-color;
      }
    }
  }


}


@media only screen and (max-width: 600px) {
  .sm-container {
    .menu-item {
      margin: 0px;
      width: 50%;
      .para1{
        font-size: $para1+px;
      }
    }
  }
}