@import "utils/variables.module.scss";

.dt-container{
  padding: 20px 22px;
  .custom-time-picker {
    background: $secondary1-color;
    padding: 13px 20px;
    border-radius: 0px;
    outline: none !important;
    border-style: none;
    font-size: $para1+px;
    font-family: $font-1-heavy;
    color: $black-color;
    &:focus {
      border-color: #ccc;
    }
    &:focus {
      border-color: #ccc;
    }
  }

  .circle{
    text-transform: uppercase;
    box-sizing: border-box;
    padding: 15px;
    height: 75px;
    width: 75px;
    border: 1px solid $white-color;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: $para1+px;
    font-family: $font-1-heavy;
    color: $white-color;
    &.active{
      color: $black-color;
      background-color: $white-color;
    }
    &:hover{
      cursor: pointer;
      background-color: $secondary2-color;
    }
  }
}

@media only screen and (min-width: 400px) {
  .dt-container{
    padding-left: 32px;
    padding-right: 32px;
  }
}