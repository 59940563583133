@import "utils/variables.module.scss";

.as-cover-bloc{
  display: flex;
  position: relative;
  width: 100%;
  height: calc(100vh);
  height: calc(100svh) !important;
  min-height: min-content;
  align-items: center;
  justify-content: center;
  background-color: $primary1-color;

  .arrow{
    transform: translate(-50%, -50%);
    color: $secondary2-color;
    font-size: 40px;
    position: fixed;
    top: 50%;
    &.left{
      left: 20px;
      margin-top: -15px;
      transform: rotate(180deg);
    }
    &.right{
      right: 20px;
    }
  }


  .arrow-right{
    position: fixed;
    right: 20px;
  }

  .floating-logo{
    position: absolute;
    bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    img {
      width: 40px;
      height: 40px;
      margin-bottom: 7px;
    }
  }

}