@import "utils/variables.module.scss";


.cta-block{
  position: fixed;
  background: $black-color;
  width: 100%;
  bottom: -500px;
  left: 0;
  transition-property: bottom;
  transition-duration: 1s;
  padding: 5px;
  box-sizing: border-box;
}