@import "utils/variables.module.scss";

.th-container{
  position: relative;
  padding-top: 20px;
  padding-bottom: 10px;
  padding-left: 22px;
  padding-right: 22px;
  background: $white-color;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: -10px;


}




