@import "utils/variables.module.scss";

.loading-container{
  background-color: #ffffffc9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1500;
  &.dark{
    background-color: $primary1-color;
    .primary1-color, .MuiCircularProgress-root{
      color: white !important;
    }
  }

}