@import "utils/variables.module.scss";

.bags-container{
  padding: 20px 22px;
}

@media only screen and (min-width: 400px) {
  .bags-container{
    padding-left: 32px;
    padding-right: 32px;
  }
}