@import "utils/variables.module.scss";

.fh-container{
  position: relative;
  padding-top: 20px;
  padding-bottom: 10px;
  padding-left: 22px;
  padding-right: 22px;
  background: $white-color;
  border-bottom-right-radius: -10px;

  &.top-radius{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  &.less-padds{
    padding-top: 12px;
    padding-bottom: 0px;
  }

}

.fh-layover{
  display: flex;
  font-family: $font-1-heavy;
  font-size: $para1;
  justify-content: center;
  padding: 10px 0;
  background-color: $primary4-color;
  color: $white-color;
}


@media only screen and (min-width: 400px) {
  .fh-container{
    padding-left: 32px;
    padding-right: 32px;
  }
}

