@import "utils/variables.module.scss";
.p-relative{
  position: relative;
}

.passenger-container{
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 22px;
  padding-right: 22px;
  background: $white-color;
  &.less-padds{
    padding-top: 1px;
    padding-bottom: 1px;
  }
}
.collapsable{
  font-size: $para2+px;
  font-family: $font-1-book;
  color: $gray2-color;
  background: $white-color;
  position: absolute;
  left: 50%;
  bottom: 6px;
  transform: translateX(-50%);
  cursor: pointer;

}


@media only screen and (min-width: 400px) {
  .passenger-container{
    padding-left: 32px;
    padding-right: 32px;
  }
}

