@import "utils/variables.module.scss";

.ss-container{
  padding: 20px 40px;
  background: $primary4-color;
  border-radius: 10px;
}

@media only screen and (max-width: 600px) {
  .ss-container {
    padding: 30px 20px;
    .h5{
      font-size: $para1+px;
    }

    .para1{
      font-size: $para2+px;
    }

    .MuiButton-sizeMedium{
      padding: 5px 40px;
      font-size:  $para2+px;
    }
  }
}