@import "utils/variables.module.scss";

.cp-container{
  padding: 20px 40px;
  background: $primary4-color;
  border-radius: 10px;

  .bags-simulator-container{
    background-color: $secondary1-color;
    padding: 20px 20px;
  }
}


@media only screen and (max-width: 600px) {
  .cp-container {
    padding: 30px 20px;
    .h5{
      font-size: $para1+px;
    }

    .para1{
      font-size: $para2+px;
    }

    .MuiButton-sizeLarge{
      padding: 5px 40px;
      font-size:  $para1+px;
    }
  }
}