@import "utils/variables.module.scss";

.bk-contact-container{
  background-color: $primary4-color;
  padding: 30px 30px;

  .PhoneInputInput{
    font-size: $para2+px;
    border: 0;
    outline: 0;
    font-family: $font-1-book;
    
    &:focus{
      border: 0 !important;
      outline: 0 !important;
    }
  }
}