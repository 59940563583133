@import "utils/variables.module.scss";

.booking-cover-bloc{
  background-color: $primary4-color;

  display: table;
  position: relative;
  width: 100%;
  height: calc(100vh - 72px);
  height: calc(100svh - 72px) !important;
  min-height: min-content;
}