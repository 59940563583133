/* You can add global styles to this file, and also import other style files */
@import "utils/variables.module.scss";



html,
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  height: 100%;
  background: #E9EAEA;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.d-f {
  display: flex !important;
}

.fd-r {
  flex-direction: row !important;
}

.fd-c {
  flex-direction: column !important;
}

.jc-sa {
  justify-content: space-around !important;
}

.jc-sb {
  justify-content: space-between !important;
}

.jc-c {
  justify-content: center !important;
}

.jc-r {
  justify-content: right !important;
}

.ai-c {
  align-items: center !important;
}


.ai-t {
  align-items: flex-start !important;
}

.ai-b {
  align-items: flex-end !important;
}

.fx-1 {
  flex: 1;
}

.d-flex-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.d-flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.h-100 {
  height: 100% !important;
}

.w-100 {
  width: 100% !important;
}

//--------------------------------------
//colors

.bg-primary1-color{
  background-color: $primary1-color !important;
}

.bg-primary2-color{
  background-color: $primary2-color !important;
}

.bg-primary3-color{
  background-color: $primary3-color !important;
}

.bg-primary4-color{
  background-color: $primary4-color !important;
}

.bg-secondary1-color{
  background-color: $secondary1-color !important;
}

.bg-secondary2-color{
  background-color: $secondary2-color !important;
}

.bg-white-color{
  background-color: $white-color !important;
}

.bg-black-color{
  background-color: $black-color !important;
}  


.primary1-color{
  color: $primary1-color;
}

.primary2-color{
  color: $primary2-color;
}

.primary3-color{
  color: $primary3-color;
}
.primary4-color{
  color: $primary4-color;
}

.secondary1-color{
  color: $secondary1-color;
}
.secondary2-color{
  color: $secondary2-color;
}

.accent-color{
  color: $accent-color;
}

.white-color{
  color: $white-color;
}

.bg-white-color{
  background: $white-color;
}

.black-color{
  color: $black-color;
}
  
.black-color-opac{
  color: $black-color-opac; 
}

.gray-color{
  color: $gray-color;
}

.error-color{
  color: $error-color;
}


.c-txt {
  text-align: center;
}

.r-txt{
  text-align: right 
}

.l-txt{
  text-align: left 
}

.c-el {
  margin: 0 auto;
}

//vertical spaces
$sizes: 10;
@mixin v-space-classes {
  @for $i from 1 through $sizes + 1 {
    $height: $i * 4px;
    .v-space-#{$i} {
      width: 100%;
      height: $height;
    }

    $width: $i * 5px;
    .h-space-#{$i} {
      width: $width;
      height: 100%;
    }
  }
}


@include v-space-classes;

//fonts

.font-1-bold {
  font-family: $font-1-bold, sans-serif;
}

.font-1-heavy {
  font-family: $font-1-heavy, sans-serif;
}

.font-1-medium {
  font-family: $font-1-medium, sans-serif;
}

.font-1-book {
  font-family: $font-1-book, sans-serif;
}

.font-1-light {
  font-family: $font-1-light, sans-serif;
}

.font-1-light-bt {
  font-family: $font-1-light-bt, sans-serif;
}

//sizes

.h1 {
  font-size: $h1+px;
}

.h2{
  font-size: $h2+px;
}

.h3{
  font-size: $h3+px;
}

.h4{
  font-size: $h4+px;
}

.h5{
  font-size: $h5+px;
}

.h6{
  font-size: $h6+px;
}

.para1{
  font-size: $para1+px;
}

.para1-bis{
  font-size: $para1-bis+px;
}

.para2{
  font-size: $para2+px;
}

.para3{
  font-size: $para3+px;
}

.para4{
  font-size: $para4+px;
}

//others

.pointer {
  cursor: pointer;
}



.bs-bx {
  box-sizing: border-box;
}

.upper {
  text-transform: uppercase;
}

.cap {
  text-transform: capitalize;
}

.turncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.field {
  box-sizing: border-box;
  padding: 10px;
  border-radius: 0px;
  background: $secondary1-color;
  /*border-color: #edecec;*/
  outline: none !important;
  font-size: $para2+px !important;
  border-style: none;
  height: 48px;
  color: $black-color;
  font-family: $para2;
  &.textarea {
    height: 208px;
  }
  &:focus {
    border-color: #ccc;
    box-shadow: 0 0 0px #ccc;
  }
}

.big-size-container{
  max-width: 440px !important;  
}


//links
a {
  text-decoration: none;
}

.underline{
  text-decoration: underline;
}

.mb-a{
  flex-grow: 1;
}


.pa{
  position: absolute;
}


.img-btn{
  cursor: pointer;
  box-sizing: border-box;
  padding: 0px 10px;
  border: 1px dashed $primary1-color;
  border-radius: 10px;
  height: 60px;
  display: flex;
  background-color: $gray3-color;
  align-items: center;
  justify-content: center;
  &.more-height{
    height: 80px;
  }
  &.active{
    border: 1px solid $primary1-color;
  }
}