@import "utils/variables.module.scss";

.landing-cover-bloc{
  display: table;
  position: relative;
  width: 100%;
  height: calc(100vh);
  height: calc(100svh) !important;
  min-height: min-content;
  background-image: url(/assets/images/landing.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  .big-cp{
    font-size: $h1+px;
  }

  .floating-logo{
    position: absolute;
    bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    .img {
      width: 40px !important;
      height: 40px;
      margin-bottom: 7px;
    }
  }


}

.txt-part{
  padding: 0px 50px;
  display: flex;
  flex-direction: column;
}
.img-part{
  display: flex;
  justify-content: center;
  padding: 0px 50px;
  .drawing{
    width: 650px; 
  }
}



@media only screen and (max-width: 800px) {
  
  .txt-part{
    .h5 {
      font-size: $para1+px;
    }
    .h3 {
      font-size: $h6+px;
    }
  }

 



  .big-cp{
    font-size: $h3+px !important;
  }
}