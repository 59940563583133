@import "utils/variables.module.scss";


.traveller-block{
  position: fixed;
  background: $primary4-color;
  width: 100%;
  bottom: 0px;
  left: 0;

}

