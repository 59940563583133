@import "utils/variables.module.scss";

.location-container{
  padding: 20px 22px;

  .places-search-container{

    position: relative;
    .direction {
      position: absolute;
      right: 12px;
      top: 16px;

    }
  }

  .autocomplete{
    label.Mui-focused, label.MuiFormLabel-filled{
      background-color: $secondary1-color;
      padding: 0 10px;
      border-radius:10px 
    }
    fieldset{
      border: 0;

    }
    .MuiAutocomplete-endAdornment{
      display: none;
    }
  }

  .map-canvas {
    width: 100%;
    height: 400px;
  }
}


@media only screen and (min-width: 400px) {
  .location-container{
    padding-left: 32px;
    padding-right: 32px;
  }
}