@import "utils/variables.module.scss";

.flight-container{
  position: relative;
  padding-top: 10px;
  padding-bottom: 20px;
  padding-left: 22px;
  padding-right: 22px;
  background: $white-color;

  &.less-padds{
    padding-top: 1px;
    padding-bottom: 10px;
  }
}

@media only screen and (min-width: 400px) {
  .flight-container{
    padding-left: 32px;
    padding-right: 32px;
  }
}




