@import "utils/variables.module.scss";


.access-code-block{
  position: fixed;
  background: $primary1-color;
  width: 100%;
  bottom: 0px;
  left: 0;



}

