@import "utils/variables.module.scss";

.s-card{
  padding: 0px 15px;
  background-color: $white-color;
  display: flex;
  flex-direction: row;
  margin-top: 5px; 
  margin-bottom: 5px; 
  .img {
    
  }
}