@import "utils/variables.module.scss";

.passenger-cover-bloc{
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: calc(100vh);
  height: calc(100svh) !important;
  min-height: min-content;
  align-items: center;
  justify-content: center;
  background-color: $primary1-color;

  .ticket-container{
    max-width: 440px; 
    margin: 0 15px;
  }

  .floating-footer{
    position: absolute;
    bottom: 20px;
    display: flex;
    align-items: center;
    padding: 0px 20%;
    width: 100%;
    img {
      width: 40px;
      height: 40px;
      margin-bottom: 7px;
    }
  }

}