@import "utils/variables.module.scss";




.col-modifier{


  &.collection{
    background-color: $primary4-color;
    .cmn-color{
      color: $primary1-color;
    }
    .accent-color{
      color: $black-color;
    }
    .accent2-color{
      color: $black-color;
    }
    .accent3-color{
      color: $primary1-color;
    }
  }

  &.delivery{
    background-color: $primary1-color;
    .cmn-color{
      color: $primary4-color;
    }
    .accent-color{
      color: $secondary2-color;
    }
    .accent2-color{
      color: $white-color;
    }
    .accent3-color{
      color: $white-color;
    }

  }

  &.inactive {
    background-color: $gray-color;
  }
} 

