@import "utils/variables.module.scss";

.order-sum-container{
  .header{
    background-color: $primary3-color;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .sub-header{
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    &.bg-collection {
      background-color: $primary3-color;
    }
    &.bg-delivery {
      background-color: $primary1-color;
    }
  }

  .bg-color-collection{
    padding-top: 10px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: $primary3-color;
  }


  .bg-color-delivery{
    padding-top: 10px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: $primary1-color;
  }

  .bottom-radius {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }


}


